export default {
  // clientId: '39a63c7e-9075-43d4-92e8-7ba2aff4945f', /* BQ */
  // clientId: 'c1cbd1d6-6df3-4e85-819d-2a17bc6b7efb', /* Mentat */
  // clientId: '89a0ddae-2cdc-4cc9-9bee-d51c37fb76ae', /* Clovis */
  clientId: 'a9a05a59-3b45-4b4a-ac2c-42052d0b5ea7', /* GES */
  // redirectUri: 'https://emailfinder.dev.mentat.fr'
  // redirectUri: 'https://emailfinder.bailly.mentat.fr'
  // redirectUri: 'https://emailfinder.clovis.mentat.fr'
  redirectUri: 'https://emailfinder.groupamaes.mentat.fr'
  // redirectUri: 'http://localhost:8080/'
}
